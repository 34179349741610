import React from 'react';
import './ComingSoon.scss';
import { useSelector } from 'react-redux';

const ComingSoon = () => {
	const isEnglish = useSelector((state) => state.language.isEnglish);
	const isMyanmar = useSelector((state) => state.language.isMyanmar);
	return (
		<section className="hero-section">
			<div className="hero-title">
				<p className="hero-watermark">LEARN</p>
				<p>WELCOME TO</p>
				<h3>HIGH GROUND</h3>
			</div>

			{isEnglish && (
				<div className="hero-decription">
					<h3>High Ground Website is Coming Soon!</h3>
					<p>
						At High Ground, we specialize in providing top-tier
						development and operations talents by leveraging our
						in-house team of highly skilled professionals from
						around the globe. While we are working on putting final
						touches on our website, feel free to reach us at{' '}
						<a href="mailto:info@highground.life" target="_blank">
							info@highground.life
						</a>{' '}
						for any inqueries!
					</p>
				</div>
			)}
			{isMyanmar && (
				<div className="hero-decription">
					<h3>ငါတို့ဘယ်သူလဲ။</h3>
					<p>
						လျှင်မြန်စွာတိုးတက်ပြောင်းလဲနေသောလုပ်ငန်းခွင်တွင်
						အတွေ့အကြုံရှိသော IT professionals များသည်
						၎င်းတို့၏ပညာရပ်မျာနှင့် ကျွမ်းကျင်မှုများကို
						အကျိုးရှိစွာအသုံးချသည်။ရှူပ်ထွေးလှသော လောကထဲတွင်
						ထိပ်တန်းဖြစ်ရန် အလားအလာရှိဖို့အတွက် High Ground မှ
						လူငယ်များသည် ကိုယ့်အားကိုယ်ကိုး ရင်ဆိုင်မည့်
						စိတ်ဓာတ်ပိုင်းဆိုင်ရာဖြင့် အသင့်ဖြစ်နေသည်။
					</p>
					<h3>ငါတို့ဘာတွေလုပ်ဆောင်လဲ။</h3>
					<p>
						HighGround ၏ training Program မှ
						အရည်အချင်းပြည့်ဝသောနည့်ပညာရှင်များကိုမွေးထုတ်နိုင်ရန်ပြင်ဆင်လျက်ရှိပါတယ်။ကျွန်ုပ်တို့သည်လူတစ်ဦးချင်းစီအား
						နိုင်ငံတကာအဆင့်အတန်းမှီစေရန်
						နောက်ဆုံးပေါ်နည်းပညာများနှင့်သင်ကြားရုံသာမက
						ကမ္ဘာတစ်ဝန်းရှိ စီးပွားရေးပညာရှင်တို့၏လုပ်ငန်း လည်ပတ်ရန်
						လိုအပ်နေသောအရေးကြီးသည့် အလုပ်နေရာများအတွက် ကိုက်ညီသော
						အရည်အချင်းပြည့်ဝသည့် နည်းပညာရှင်များကို
						ချိတ်ဆက်ပေးလျက်ရှိပါသည်။
					</p>
					<p>High Ground မှာ ကျွန်ုပ်တို့နှင့် ပူးပေါင်းပါ။</p>
				</div>
			)}
		</section>
	);
};

export default ComingSoon;
