import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { useLocation } from "react-router-dom";

const MainLayout = ({ children }) => (
	<div className="App">
		{/* <Header /> */}
		<main className="main-content">{children}</main>
		{/* <Footer /> */}
	</div>
);

const MainRoute = ({ component: Component, protectedRoute, ...rest }) => {
	const location = useLocation();
	const isLoggedIn = useSelector(
		(state) => state.user && state.user.user !== null
	);
	return (
		<Route
			{...rest}
			render={(props) => {
				if (protectedRoute && !isLoggedIn) {
					return (
						<Redirect to={{ pathname: "/signin", state: location.pathname }} />
					);
				} else {
					return (
						<MainLayout>
							<Component {...props} />
						</MainLayout>
					);
				}
			}}
		/>
	);
};

export default MainRoute;
