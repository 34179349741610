import React, { useEffect, useState } from "react";
import "./StudentWorks.scss";
import hgLogo from "../../assets/svg/HG-logo-full.svg";
import Radiobutton from "../../components/RadioButton/Radiobutton";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentWorks } from "../../ducks/modules/student";
import { Button, Dialog, DialogActions, } from "@material-ui/core";
import DialogContent from '@material-ui/core/DialogContent';
import { DialogContentText } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PortfolioList from "../../components/PortfolioList/PortfolioList";

const StudentWorks = () => {
	const studentWorks = useSelector((state) => state.student.studentWorks);
	const dispatch = useDispatch();
	const [dialogOpen, setDialogOpen] = useState(false);
	const history = useHistory();
	const isLoggedIn = useSelector(
		(state) => state.user && state.user.user !== null
	);

	useEffect(() => {
		async function fetchData() {
			await dispatch(fetchStudentWorks());
		}
		fetchData();
	}, []);

	const openRepo = (student) => {
		if(isLoggedIn) window.open(student.portfolio_url, '_blank');
		else setDialogOpen(true) 
	};
	
	return (
		<div className="stworks">
			<div className="stworks-upper">
				<h1 className="stworks-upper-header">Student Works</h1>
			</div>

			

			<section className="stworks-middle">
				<div className="stworks-filter-container">
					<div className="stworks-filter-container-buttons">
						<Radiobutton htmlText="Basic Web Development" selected={true} />
					</div>
				</div>
				{studentWorks &&
					studentWorks.map((student, index) => (
						<div key={index} className="stworks-middle-container">

							<section className="stworks-middle-content-container">
								<div className="stworks-middle-content-left">
									<div className="circular">
										<img
											className="stworks-middle-content-left-image"
											src={
												student.avatar_url !== null
													? student.avatar_url
													: hgLogo
											}
											alt="High Ground Logo"
										/>
									</div>
									<div className="stworks-margin-left stworks-middle-content-left-inner">
										
										<div className="stworks-middle-content-left-inner-right-side">
											<h3>{student.name}</h3>
											<p> Basic Web Development</p>
										</div>
									</div>
								</div>
								{/* right */}
								<section className="work">
									<div className="repository-link linkbox" onClick={()=>openRepo(student)}>
										Repository
									</div>
									<PortfolioList setDialogOpen={setDialogOpen} student={student}></PortfolioList>
								</section>
								{/* // <div className="uncovered-view">
								// 	<div className="frosty-glass">
								// 		<a href="#">{lorem.generateWords(2)}</a>
								// 	</div>
								// </div>
								// } */}
							</section>
						</div>
					))}
			</section>
			<Dialog open={dialogOpen} className="dialog">
				<DialogContent>
					<DialogContentText>Please sign in to view student works</DialogContentText>
					<DialogActions>
						<Button onClick={()=>history.push('/signin')}>Sign In</Button>
						<Button onClick={()=>setDialogOpen(false)}>Cancel</Button>
					</DialogActions>
				</DialogContent>
			
			</Dialog>
		</div>
	);
};

export default StudentWorks;
