import { createStore, applyMiddleware, combineReducers } from 'redux';
import user from './modules/user';
import loading from './modules/loading';
import snackbar from './modules/snackbar';
import course from './modules/course';
import student from './modules/student';
import language from './modules/language';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import persistCombineReducers from 'redux-persist/es/persistCombineReducers';
import localForage from 'localforage';
//import storage from 'redux-persist/lib/storage'

const { logger } = require(`redux-logger`);

export const reducer = combineReducers({
	user,
	loading,
	snackbar,
	course,
	student,
	language,
});

export const persistConfig = {
	key: 'root',
	storage: localForage,
	whitelist: ['user'],
};
const persistedReducer = persistReducer(persistConfig, reducer);

let store = createStore(persistedReducer, applyMiddleware(thunk, logger));
const persistor = persistStore(store);

export { store, persistor };
