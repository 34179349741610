import React, { useEffect, useState } from 'react';

const VimeoPlayer = ({ vimeoId }) => {
	const [imageSrc, SetImageSrc] = useState('');


	return (
		<iframe src={"https://player.vimeo.com/video/" + vimeoId} 
			className="vimeo-player"
			width="100%"
			height="100%" 
			frameborder="0" 
			allow="autoplay; fullscreen; picture-in-picture" 
			allowfullscreen>
			
		</iframe>
		
	);
};

export default VimeoPlayer;
