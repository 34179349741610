import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Form, Formik, FieldArray, Field, ErrorMessage } from 'formik';
import { updateProfile, fetchUserProfile } from '../../ducks/modules/user';
import { useDispatch, useSelector } from 'react-redux';

import hgLogo from '../../assets/svg/HG-logo-full.svg';
import AddOneMore from './../../components/AddOneMore/AddOneMore';
import Avatar from '../../components/Avatar/Avatar';
import Button from './../../components/Button/Button';
import IconButton from './../../components/IconButton/IconButton';
import Input from './../../components/Input/Input';
import Tab from './../../components/Tab/Tab';
import Portfolio from './Portfolio';
import './Profile.scss';

const personalInformationInitialValues = {
	name: '',
	email: '',
	phone_number: '',
	password: '',
	confirmPassword: '',
	portfolio_url: '',
};

const personalInformationSchema = Yup.object().shape({
	name: Yup.string().required('Name is required.'),
	email: Yup.string().required('Email is required.').email('Invalid email.'),
	phone_number: Yup.string()
		.matches(/^[0-9]+$/, 'Invalid mobile number.')
		.required('Mobile number is required.'),
	password: Yup.string().min(8, 'Password must me more than 8 characters.'),
	confirmPassword: Yup.string().when('password', {
		is: (password) => password,
		then: Yup.string()
			.oneOf(
				[Yup.ref('password'), null],
				'Confirm password must match with password.'
			)
			.required('Confirm password is required'),
	}),
	portfolio_url: Yup.string()
		.url('Repository link must be a valid url.')
});



const Profile = () => {
	const [activeForm, setActiveForm] = useState('personalInformation');
	const [profileValues, setProfileValues] = useState(
		personalInformationInitialValues
	);

	const user = useSelector((state) => state.user.user);

	const dispatch = useDispatch();

	useEffect(() => {
		setProfileValues(user);
	}, [user]);

	const onSubmitPersonalInformation = async (values, action) => {
		let result = await dispatch(updateProfile(values));
		let savedData = result.data;
		savedData.password = '';
		savedData.confirmPassword = '';
		setProfileValues(result.data);
	};


	const showPersonalInformationForm = () => {
		setActiveForm('personalInformation');
	};

	const showPortfolioForm = () => {
		setActiveForm('portfolio');
	};

	return (
		<div className="profile">
			<h1 className="title">Profile</h1>
			<div className="content">
				<Avatar
					src={user.avatar_url !== null ? user.avatar_url : hgLogo}
					alt="profile"
					isEditable
				/>
				<div className="tabs">
					<Tab
						label="Personal Information"
						isActive={activeForm === 'personalInformation'}
						onClick={showPersonalInformationForm}
					/>
					<Tab
						label="Your Portfolio"
						isActive={activeForm === 'portfolio'}
						onClick={showPortfolioForm}
					/>
				</div>
				{activeForm === 'personalInformation' ? (
					<Formik
						initialValues={{
							name: profileValues.name || '',
							email: profileValues.email || '',
							phone_number: profileValues.phone_number || '',
							password: profileValues.password || '',
							confirmPassword:
								profileValues.confirmPassword || '',
							portfolio_url: profileValues.portfolio_url || '',
						}}
						validationSchema={personalInformationSchema}
						onSubmit={onSubmitPersonalInformation}
						enableReinitialize={true}
					>
						{({ isValid }) => (
							<Form className="form">
								<Input name="name" label="Name" />
								<Input
									name="email"
									type="email"
									label="Email"
								/>
								<Input
									name="phone_number"
									type="tel"
									label="Phone number"
								/>
								<div className="input-container">
									<Input
										name="password"
										type="password"
										label="Password"
										className="form-control rounded-o"
										autoComplete="current-password"
									/>
								</div>

								<div className="input-container">
									<Input
										name="confirmPassword"
										type="password"
										label="Confirm password"
										autoComplete="current-password"
									/>
								</div>
								<Input
									name="portfolio_url"
									type="url"
									label="Repository link"
								/>
								<div className="button__container">
									<Button
										label="Save"
										type="submit"
										isDisabled={!isValid}
									/>
								</div>
							</Form>
						)}
					</Formik>
				) : (
					<Portfolio></Portfolio>
				)}
			</div>
		</div>
	);
};

export default Profile;
