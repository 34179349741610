export const LOADING_SHOW = "loading/show"
export const LOADING_HIDE = "loading/hide"  

const initialState = {
	on: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_SHOW:
			return { ...state, on: true }
		case LOADING_HIDE: 
			return { ...state, on: false }
		default:
      return state;
	}
}

// todo: pass specific content
export const showLoading = () => {
	return async (dispatch) => {
		try{
			dispatch({ type: LOADING_SHOW })
		}
		catch (error) {
			// handle error accordingly
			console.log(error)
		}
	};
};

export const hideLoading = () => {
	return async (dispatch) => {
		try{
			dispatch({ type: LOADING_HIDE })
		}
		catch (error) {
			// handle error accordingly
			console.log(error)
		}
	};
};


export default reducer