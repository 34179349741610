import React from 'react';

import addIcon from '../../assets/svg/add.svg';

import './AddOneMore.scss';
import { PropTypes } from 'prop-types';

const AddOneMore = ({ onClick }) => {
	return (
		<button className="AddOneMore" type="button" onClick={onClick}>
			<img src={addIcon} alt="add" />
			<span>Add one more</span>
		</button>
	);
};
AddOneMore.propTypes = {
	onClick: PropTypes.func.isRequired,
};

export default AddOneMore;
