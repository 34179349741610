import axios from 'axios';
import { LOADING_HIDE, LOADING_SHOW } from './loading';

export const FETCH_STUDENT_WORKS = 'student/set/info';

const initialState = {
	studentWorks: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_STUDENT_WORKS:
			return { ...state, studentWorks: action.studentWorks };
		default:
			return state;
	}
};

export const fetchStudentWorks = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: LOADING_SHOW });
			let studentData = await axios.get(`students`);
			dispatch({
				type: FETCH_STUDENT_WORKS,
				studentWorks: studentData.data,
			});
			dispatch({ type: LOADING_HIDE });
		} catch (error) {
			console.log(error);
			dispatch({ type: LOADING_HIDE });
		}
	};
};

export default reducer;
