import { useEffect, useState } from 'react';
import './Courses.scss';

import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Courses = () => {
	const [courses, setCourses] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			const result = await axios.get('courses');
			setCourses(result.data);
		};
		fetchData();
	}, []);

	return (
		<section className="courses">
			<h3 className="section-title">Courses</h3>
			{courses &&
				courses.map((course, index) => (
					<div className="course-container" key={course.id}>
						<div className="course-image-wrapper">
							<img src={"/images/course" + (index % 3 + 1) + ".jpeg"} alt={course.name} />
						</div>
						<div className="course-summary">
							<div>
								<h4 className="course-title">
									{course.name}
								</h4>
								<p className="course-information">
									<span className="total-lessons">
										Total lesson :
									</span>
									<span>{course.totalLessons}</span>
									<span className="total-duration">
										Duration :
									</span>
									<span>{course.duration}</span>
								</p>
								<p className="course-description">
									{ReactHtmlParser(course.description)}
								</p>
							</div>
							<Link
								className="lesson-detail"
								to={`/courses/${course.id}/details`}
							>
								<button className="lesson-detail-button">
									See lessons
								</button>
							</Link>
						</div>
					</div>
				))}
			{/* <div className="courses-pagination">
				<button>Previous</button>
				<button className="pagination-active">1</button>
				<button>2</button>
				<button>3</button>
				<button>Next</button>
			</div> */}
		</section>
	);
};

export default Courses;
