import React from "react";
import { Link } from "react-router-dom";
import notFound from "../../assets/svg/not-found.svg";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="not-found-content">
      <div>
        <img className="not-found-img" src={notFound} alt="" />
      </div>
      <div>Page Not Found</div>
    </div>
  );
};

export default NotFound;
