import React, { useEffect, useState } from 'react';
import Checkbox from '../../assets/svg/checkbox.svg';
import './Radiobutton.scss';

const Radiobutton = ({ htmlText, selected }) => {
	return (
		<button className="button-container">
			{selected ? (
				<img className="selected" src={Checkbox} />
			) : (
				<div className="unselected" />
			)}
			<p>{htmlText}</p>
		</button>
	);
};

export default Radiobutton;
