export const SHOW_SUCCESS = 'success/show';
export const SHOW_ERROR = 'error/show';
export const HIDE_SNACKBAR = 'snackbar/hide';

const initialState = {
	errorOn: false,
	errorMessage: '',
	successOn: false,
	successMessage: '',
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_SUCCESS:
			return {
				...state,
				successOn: true,
				successMessage: action.message,
			};
		case SHOW_ERROR:
			return { ...state, errorOn: true, errorMessage: action.message };
		case HIDE_SNACKBAR:
			return {
				...state,
				errorOn: false,
				successOn: false,
				successMessage: '',
				errorMessage: '',
			};
		default:
			return state;
	}
};

export const showSuccess = (message) => {
	return async (dispatch) => {
		try {
			dispatch({ type: SHOW_SUCCESS, message });
		} catch (error) {
			// handle error accordingly
			console.log(error);
		}
	};
};

export const showError = (message) => {
	return async (dispatch) => {
		try {
			dispatch({ type: SHOW_ERROR, message });
		} catch (error) {
			// handle error accordingly
			console.log(error);
		}
	};
};

export const hideSnackBar = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: HIDE_SNACKBAR });
		} catch (error) {
			// handle error accordingly
			console.log(error);
		}
	};
};

export default reducer;
