import React from 'react';
import { PropTypes } from 'prop-types';

import './IconButton.scss';

const IconButton = ({ src, onClick }) => {
	return (
		<button
			className="IconButton"
			style={{
				backgroundImage: `url(${src})`,
			}}
			onClick={onClick}
		/>
	);
};
IconButton.propTypes = {
	src: PropTypes.string.isRequired,
};

export default IconButton;
