import React from 'react';  
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';  
  
const AuthLayout = ({ children }) => (                         
	<div className="App">
		<main className="main-content">
			{children}                     
		</main>                  
	</div>  
);  

const AuthRoute = ({component: Component, ...rest}) => {  
	const isLoggedIn = useSelector(
		(state) => state.user && state.user.user !== null
	);
	return (  
		isLoggedIn? <Redirect to={'/'}></Redirect>:
		<Route {...rest} 
			render={props => {
				if(isLoggedIn) {
					<Redirect to="/" />
				}
				else {
					return (
						<AuthLayout>  
							<Component {...props} />  
						</AuthLayout>  
					)
				}
			}}
		/>  
	)
};  
  
export default AuthRoute;  