import { truncate } from 'lodash';

export const CHANGE_INTO_ENGLISH = 'CHANGE_INTO_ENGLISH';
export const CHANGE_INTO_MYANMAR = 'CHANGE_INTO_MYANMAR';

const initialState = {
	isEnglish: true,
	isMyanmar: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_INTO_ENGLISH:
			return {
				...state,
				isEnglish: action.isEnglish,
				isMyanmar: !action.isEnglish,
			};
		case CHANGE_INTO_MYANMAR:
			return {
				...state,
				isMyanmar: action.isMyanmar,
				isEnglish: !action.isMyanmar,
			};
		default:
			return state;
	}
};

export const changeIntoEnglish = (id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: CHANGE_INTO_ENGLISH,
				isEnglish: true,
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const changeIntoMyanmar = (id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: CHANGE_INTO_MYANMAR,
				isMyanmar: true,
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export default reducer;
