import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Form, Formik, FieldArray, Field, ErrorMessage } from 'formik';
import Input from './../../components/Input/Input';
import AddOneMore from './../../components/AddOneMore/AddOneMore';
import Button from './../../components/Button/Button';
import IconButton from './../../components/IconButton/IconButton';
import subtractIcon from '../../assets/svg/subtract.svg';
import { useDispatch, useSelector } from 'react-redux';
import { LOADING_HIDE, LOADING_SHOW } from '../../ducks/modules/loading';

const portfolioValues = {
	portfolios: [
		{
			title: '',
			link: '',
		},
	],
};

const portfolioSchema = Yup.object().shape({
	portfolios: Yup.array().of(
		Yup.object().shape({
			title: Yup.string().required('Portfolio title is required.'),
			link: Yup.string()
				.url('Portfolio link must be a valid url.')
				.required('Portfolio link is required.'),
		})
	),
});

const Portfolio = () => {
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	useEffect(() => {
		const getPortfolio = async () => {
			try {
				dispatch({ type: LOADING_SHOW });
				const result = await axios.get(`profile/portfolio/${user.id}`);
				// setPortfolio(result.data);
				portfolioValues.portfolios = result.data;
			} finally {
				dispatch({ type: LOADING_HIDE });
			}
		};
		getPortfolio();
	}, []);

	const submitPortfolio = async (values) => {
		try {
			dispatch({ type: LOADING_SHOW });
			const result = await axios.post(
				'/profile/portfolio',
				values.portfolios
			);
			//setPortfolio(result.data);
			dispatch({ type: LOADING_HIDE });
		} finally {
			dispatch({ type: LOADING_HIDE });
		}
	};

	return (
		<div className="upload-portfolio">
			<Formik
				initialValues={portfolioValues}
				validationSchema={portfolioSchema}
				onSubmit={submitPortfolio}
			>
				{({ values }) => (
					<Form className="form">
						<FieldArray name="portfolios">
							{({ insert, remove, push, setFieldValue }) => (
								<div>
									{values.portfolios.length > 0 &&
										values.portfolios.map(
											(portfolio, index) => (
												<div
													className="portfolio-link-input"
													key={index}
												>
													<div>
														<label
															htmlFor={`portfolios.${index}.title`}
														>
															Title
														</label>
														<Input
															name={`portfolios.${index}.title`}
															placeholder="Portfolio Title"
															type="text"
														/>
													</div>
													<div>
														<label
															htmlFor={`portfolios.${index}.link`}
														>
															Link
														</label>
														<Input
															name={`portfolios.${index}.link`}
															placeholder="Portfolio Link"
															type="text"
														/>
													</div>
														<div className="icon-button-container">
															<IconButton
																src={subtractIcon}
																onClick={() =>
																	remove(index)
																}
															/>
														</div>
												</div>
											)
										)}
									<AddOneMore
										onClick={() =>
											push({
												title: '',
												link: '',
											})
										}
									/>
								</div>
							)}
						</FieldArray>
						<Button
							label="Update portfolio"
							type="submit"
							// isDisabled={!isValid}
						/>
					</Form>
				)}
			</Formik>
		</div>
	);
};
export default Portfolio;
