import React, { useState } from "react";
import "./PortfolioList.scss";
import { useSelector } from "react-redux";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropDown';
import { Icon } from "@material-ui/core";

const PortfolioList = (props) => {
	const student = props.student;
	const setDialogOpen = props.setDialogOpen;
	const [isOpen, setIsOpen] = useState(false);
	const isLoggedIn = useSelector(
		(state) => state.user && state.user.user !== null
	);

	const expandPortfolio = () => {
		if(isLoggedIn) setIsOpen(!isOpen);
		else setDialogOpen(true) 
	};

	const goToLink = (portfolio) => {
		window.open(portfolio.link, '_blank');
	}

	return (
		<div>
			<div className="portfolio-header linkbox" onClick={()=>expandPortfolio()}>
				Portfolio <Icon component={ArrowDropUpIcon} className={`arrow ${isOpen&&'reverse'}`}></Icon>
			</div>
			{student.portfolios.map((portfolio, index) => 
				<div key={index} onClick={()=>goToLink(portfolio)} className={`portfolio-box ${!isOpen&&'closed'}`}>{portfolio.title}</div>
			)}
		</div>
	);
};

export default PortfolioList;
