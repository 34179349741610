import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Button from '../Button/Button';
import { useDispatch } from 'react-redux';
import { showSuccess } from '../../ducks/modules/snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import './Avatar.scss';
import axios from 'axios';
import { updateAvatarURL } from '../../ducks/modules/user';

const Avatar = ({ alt = 'avatar', src, isEditable = true }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const handleSubmission = async (event) => {
		var formData = new FormData();
		formData.append('file', event.target.files[0]);
		setLoading(true);
		await dispatch(updateAvatarURL(formData));
		setLoading(false);
	};

	return (
		<div className={`Avatar__container`}>
			<img className="Avatar" src={src} alt={alt} />
			{loading && (
				<div className="overlay">
					<CircularProgress
						className="overlay-circle"
						color="secondary"
					/>
				</div>
			)}

			{isEditable && (
				<div className="Avatar__edit">
					<input
						id="image-upload"
						type="file"
						accept=".png, .jpg, .jpeg"
						onChange={handleSubmission}
					/>
					<label htmlFor="image-upload" />
				</div>
			)}
		</div>
	);
};

Avatar.propTypes = {
	alt: PropTypes.string,
	src: PropTypes.string.isRequired,
	isEditable: PropTypes.bool,
};

export default Avatar;
