import React from 'react';
import { PropTypes } from 'prop-types';

import './Tab.scss';

const Tab = ({ label, isActive, onClick }) => {
	return (
		<button
			className={`Tab ${
				isActive ? 'Tab--active' : 'Tab--inactive'
			}`}
			onClick={onClick}
		>
			{label}
		</button>
	);
};

Tab.propTypes = {
	label: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default Tab;
