import React from 'react';
import { useField } from 'formik';
import { PropTypes } from 'prop-types';

import './Input.scss';

const Input = ({
	autocomplete = '',
	disabled = false,
	placeholder = '',
	readOnly = false,
	type = 'text',
	label,
	name,
}) => {
	const [field, meta] = useField(name);

	return (
		<>
			<div className="Input">
				{label && <label htmlFor={name}>{label}</label>}

				<div className="input__container">
					<input
						autoComplete={autocomplete}
						disabled={disabled}
						id={name}
						placeholder={placeholder}
						readOnly={readOnly}
						type={type}
						className={`input input__${type}`}
						{...field}
					/>

					{type === 'tel' && (
						<div className="input__tel__label">
							+95&nbsp;&nbsp;|
						</div>
					)}
				</div>

				<div className="error">{meta.touched && meta.error}</div>
			</div>
		</>
	);
};

Input.propTypes = {
	autocomplete: PropTypes.string,
	disabled: PropTypes.bool,
	enableShowPassword: PropTypes.bool,
	placeholder: PropTypes.string,
	readOnly: PropTypes.bool,
	type: PropTypes.string,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

export default Input;
