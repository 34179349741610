import { Provider, useDispatch, useSelector } from 'react-redux';
import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
	useLocation,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from './ducks/store';
import AuthRoute from './layouts/AuthRoute';
import MainRoute from './layouts/MainRoute';
import CourseDetails from './pages/CourseDetails/CourseDetails';
import Courses from './pages/Courses/Courses';
import Landing from './pages/Landing/Landing';
import ComingSoon from './pages/ComingSoon/ComingSoon';
import NotFound from './pages/NotFound/NotFound';
import Profile from './pages/Profile/Profile';
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
import StudentWorks from './pages/StudentWorks/StudentWorks';

import './assets/styles/Global.scss';
import { hideSnackBar } from './ducks/modules/snackbar';

import { isIE, browserVersion } from 'react-device-detect'
import NoSupport from './pages/NoSupport/NoSupport';
require('./boostrap.js');

const HGRouter = () => {
	const Alert = (props) => {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	};
	const open = useSelector((state) => state.loading.on);
	const snackBar = useSelector((state) => state.snackbar);
	// const snackOn = useSelector(state => state.snackbar.errorOn || state.snackbar.successOn );
	// const successOn = useSelector(state => state.snackbar.successOn)
	// const successMessage = useSelector(state => state.snackbar.successMessage)
	// const errorOn = useSelector(state => state.snackbar.errorOn)
	// const errorMessage = useSelector(state => state.snackbar.errorMessage)
	const dispatch = useDispatch();
	return (
		<Router>
			<Backdrop open={open}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Snackbar
				autoHideDuration={2000}
				open={snackBar.successOn || snackBar.errorOn}
				onClose={() => dispatch(hideSnackBar())}
			>
				<div>
					{snackBar.successOn && (
						<Alert
							onClose={() => dispatch(hideSnackBar())}
							severity="success"
						>
							{snackBar.successMessage}
						</Alert>
					)}
					{snackBar.errorOn && (
						<Alert
							onClose={() => dispatch(hideSnackBar())}
							severity="error"
						>
							{snackBar.errorMessage}
						</Alert>
					)}
				</div>
			</Snackbar>
			<Switch>
				<MainRoute exact path="/" component={ComingSoon} />
				<MainRoute path="/courses" exact={true} component={Courses} />
				<MainRoute
					path="/courses/:courseId/details"
					exact={true}
					component={CourseDetails}
				/>
				<MainRoute
					path="/students/works"
					exact={true}
					component={StudentWorks}
				/>
				<MainRoute
					path={'/profile/:userID'}
					exact={true}
					component={Profile}
					protectedRoute={true}
				/>
				<AuthRoute path="/signup" exact={true} component={SignUp} />
				<AuthRoute path="/signin" exact={true} component={SignIn} />
				<MainRoute component={NotFound} />
			</Switch>
		</Router>
	);
};

const App = () => {
	if (isIE && browserVersion < 11)
    return (
      <NoSupport></NoSupport>
    )
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<HGRouter></HGRouter>
			</PersistGate>
		</Provider>
	);
	// return <NoSupport></NoSupport>
};

export default App;
