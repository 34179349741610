import axios from 'axios';
import { LOADING_HIDE, LOADING_SHOW } from './loading';

export const FETCH_COURSES = 'course/set/info';

const initialState = {
	courses: '',
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_COURSES:
			return { ...state, courses: action.courses };
		default:
			return state;
	}
};

export const fetchCourses = (id) => {
	return async (dispatch) => {
		try {
			dispatch({ type: LOADING_SHOW });
			let courseData = await axios.get(`courses/${id}`);
			dispatch({ type: FETCH_COURSES, courses: courseData.data });
			dispatch({ type: LOADING_HIDE });
		} catch (error) {
			console.log(error);
			dispatch({ type: LOADING_HIDE });
		}
	};
};

export default reducer;
