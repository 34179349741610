import React, { useEffect, useState } from 'react';

const YoutubeImage = ({ videoLink }) => {
	const [imageSrc, SetImageSrc] = useState('');

	useEffect(() => {
		function getId(url) {
			var regExp =
				/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
			var match = url.match(regExp);

			if (match && match[2].length == 11) {
				return match[2];
			} else {
				return 'error';
			}
		}

		var myId = getId(videoLink);
		SetImageSrc(myId);
	}, [videoLink]);

	return (
		<iframe
			width="100%"
			height="100%"
			src={`https://www.youtube.com/embed/${imageSrc}?modestbranding=1&modestbrowsing=1`}
			frameBorder="0"
			allowFullScreen
		></iframe>
	);
};

export default YoutubeImage;
