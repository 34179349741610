import axios from 'axios';
import { LOADING_HIDE, LOADING_SHOW } from './loading';
import { showSuccess, showError } from './snackbar';
import Cookies from 'js-cookie'

export const AUTH_SET_INFO = 'auth/set/info';
export const AUTH_REMOVE_INFO = 'auth/remove/info';
export const GET_PROFILE = 'get/profile/info';
export const PROFILE_PHOTO_UPDATED = 'profile/photo/updated'


const initialState = {
	user: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_SET_INFO:
			return { ...state, user: action.user };
		case AUTH_REMOVE_INFO:
			return { ...state, user: null, timestamp: null };
		case GET_PROFILE:
			return { ...state, user: action.data };
		case PROFILE_PHOTO_UPDATED:
			return { ...state, user: {...state.user, avatar_url: action.file }}
		default:
			return state;
	}
};

export const authenticate = (email, password) => {
	return async (dispatch) => {
		try {
			dispatch({ type: LOADING_SHOW });
			await axios.get('sanctum/csrf-cookie');
			const result = await axios.post('authenticate', {
				email: email,
				password: password,
			});

			setTimeout(() => {
				dispatch({ type: AUTH_SET_INFO, user: result.data.user });
				dispatch({ type: LOADING_HIDE });
			}, 2000);
		} catch (error) {
			console.log(error);
			dispatch({ type: LOADING_HIDE });
			dispatch(showError('Login Failed!'));
		}
	};
};

export const logOut = () => {
	return async (dispatch) => {
		dispatch({ type: LOADING_SHOW });
		await axios.post('logout');
		localStorage.removeItem('TOKEN');
		dispatch({ type: LOADING_HIDE });
		dispatch({ type: AUTH_REMOVE_INFO });
	};
};

export const fetchUserProfile = () => {
	return async (dispatch) => {
		try {
			const result = await axios.get(`profile`);
			dispatch({ type: GET_PROFILE, data: result.data });
		} catch (err) {
			console.log(err);
		}
	};
};

export const updateProfile = (values) => {
	return async (dispatch) => {
		try {
			dispatch({ type: LOADING_SHOW });
			let result = await axios.post('profile', values);
			dispatch(showSuccess('Profile successfully updated'));
			return result;
		} finally {
			dispatch({ type: LOADING_HIDE });
		}
	};
};

export const updateAvatarURL = (formData) => {
	return async (dispatch) => {
		axios.post('/avatar', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			dispatch({ type: PROFILE_PHOTO_UPDATED, file: res.data.file });
			dispatch(showSuccess('Profile Upload Success'));
		})
		.catch((err) => {
			// alert for now
			dispatch(showSuccess('Profile photo updated'));
			console.log(err);
		})
		
	};
}

export const forgotPassword = (email) => {
	return async (dispatch) => {
		try {
			dispatch({ type: LOADING_SHOW });
			try {
				let result = await axios.post('forgot', {
					type: 'sendEmail',
					email: email,
				});
				return result;
			} finally {
				dispatch({ type: LOADING_HIDE });
			}
		} catch (error) {
			console.log(error);
		}
	};
};

export default reducer;
