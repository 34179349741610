import React from 'react';
import { useDispatch } from 'react-redux';
import hgLogo from '../../assets/svg/HG-logo-full.svg';

import './SignIn.scss';
import { authenticate } from '../../ducks/modules/user';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const SignIn = ({ location }) => {
	const dispatch = useDispatch();
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const loginClicked = ($event) => {
		$event.preventDefault();
		dispatch(authenticate(email, password));
	};
	return (
		<section className="auth">
			{/* Top */}
			<div className="auth-nav">
				<div className="auth-nav-language">
					<button className="mm-button">MM</button>
					<button className="eng-button">ENG</button>
				</div>
				<div className="auth-nav-logo">
					<Link to={{ pathname: '/', state: location.pathname }}>
						<img
							className="auth-nav-logo-image"
							src={hgLogo}
							alt="High Ground Logo"
						/>
					</Link>
				</div>
				<div className="auth-nav-sign-up">
					{/* <button>Sign Up</button> */}
				</div>
			</div>
			{/* Middle */}
			<div className="auth-form">
				<div className="auth-form-content">
					<h3>Sign In</h3>
					<p>
						Hi there, sign in to check students’ work and get
						inspired.
					</p>
				</div>

				<div className="auth-form-item">
					<form onSubmit={loginClicked}>
						<div className="input-area">
							<div className="label">Email</div>
							<input
								type="email"
								className="input"
								placeholder="email"
								required
								onChange={(event) =>
									setEmail(event.target.value)
								}
							/>
						</div>

						<div className="input-area">
							<div className="label">Password</div>
							<input
								type="password"
								className="input"
								placeholder="Password"
								required
								onChange={(event) =>
									setPassword(event.target.value)
								}
							/>
						</div>

						<div className="center">
							<button className="signin-button" type="submit">
								Sign In
							</button>
						</div>
					</form>
				</div>
			</div>

			{/* Bottom */}
			<div className="auth-forgot-password">
				<p>Forgot password?</p>
			</div>
		</section>
	);
};

export default SignIn;
