
import axios from "axios";
import { AUTH_REMOVE_INFO } from "./ducks/modules/user";
import { store } from "./ducks/store";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers['accept'] = 'application/json';
axios.interceptors.request.use(
	function (req) {
    return req;
  }
);
axios.interceptors.response.use((response) => {
	return response
}, error => {
	if(error.response) {
		if(error.response.status == 401) {
			store.dispatch({ type: AUTH_REMOVE_INFO })
		}
		else {
			return Promise.reject(error)
		}
		return Promise.reject(error)
	}
	else {
		store.dispatch({ type: AUTH_REMOVE_INFO })
		return Promise.reject(error)
	}
})