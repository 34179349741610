import React from "react";
import hgLogo from "../../assets/svg/HG-logo-full.svg";
import "./SignUp.scss";

const SignUp = () => {
	return (
		<div class="sign-up-screen">
			<div class="logoarea-web">
				<div class="logo-wrapper">
					<div class="button-margin">
						<button class="mm-button">MM</button>
						<button class="eng-button">ENG</button>
					</div>
					<div>
						<a href="/">
							<img className="logo" src={hgLogo} alt="High Ground Logo" />
						</a>
					</div>
					<div class="sign-in">
						<a href="/signin">Sign In</a>
					</div>
				</div>
			</div>
			<div class="logoarea-mobile">
				<div class="logo-wrapper">
					<img className="logo" src={hgLogo} alt="High Ground Logo" />
					<div class="button-margin">
						<button class="mm-button">MM</button>
						<button class="eng-button">ENG</button>
					</div>
					<div class="sign-in">
						<a href="/signin">Sign In</a>
					</div>
				</div>
			</div>

			<div class="sign-up">
				<div class="content">
					<div class="title-area">
						<h3 class="title">Sign Up</h3>
						<p class="sub-title">
							Hi there, sign up to check students’ work and get inspired.
						</p>
					</div>

					<div class="form-sign-up">
						<div className="input-area">
							<div class="label">Name</div>
							<input type="Name" className="column" placeholder="Name" />
						</div>

						<div className="input-area">
							<div class="label">Email</div>
							<input type="Email" className="column" placeholder="Email" />
						</div>

						<div className="input-area">
							<div class="label">Password</div>
							<input
								type="Password"
								className="change-margin"
								placeholder="Password"
							/>
						</div>

						<div class="center">
							<button className="signUp-button">Sign Up</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
