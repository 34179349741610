import React from "react";
import { Link } from "react-router-dom";
import noSupport from "../../assets/images/nosupport.png";
import "../../assets/styles/Global.scss"
import "./NoSupport.scss";
import Button from "../../components/Button/Button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdge, faInternetExplorer } from '@fortawesome/free-brands-svg-icons'

const NoSupport = () => {
  return (
    <div className="nosupport">
      <div>
        <img src={noSupport} alt="No Support" />
      </div>
      <h1>Your Web Browser Is Not Supported</h1>
			<p>Our website does not support your current version of your browser. Please update your browser to access High Ground.</p>
			<p>사용중인 브라우저는 High Ground 웹사이트에서 지원하지 않습니다. 사용을 하시기 위해서는 브라우저를 업데이트하시기 바랍니다.</p>
			<p></p>
			<section className="buttons">
				<Button href="https://www.microsoft.com/en-us/edge"><FontAwesomeIcon icon={faEdge}></FontAwesomeIcon> Download Microsoft Edge</Button>
				<Button href="https://www.microsoft.com/en-us/download/internet-explorer.aspx"><FontAwesomeIcon icon={faInternetExplorer}></FontAwesomeIcon>Download Internet Explorer 11</Button>
			</section>
    </div>
  );
};

export default NoSupport;
