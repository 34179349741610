import React from 'react';
import { PropTypes } from 'prop-types';

import './Button.scss';


const Button = ({ isDisabled, label, onClick, type = 'button', children, className, href }) => {
	const onClickButton = () => {
		if(href) {
			window.open(href);
		}
		else if(onClick) {
			onClick();
		}
	}
	return (
		<button
			className={`button ${isDisabled ? 'button--disabled' : ''} ${className}`}
			disabled={isDisabled}
			type={type}
			onClick={onClickButton}
		>
			{label?label:children}
		</button>
	);
};

Button.propTypes = {
	isDisabled: PropTypes.bool,
	label: PropTypes.string.isRequired,
	type: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.node,
	className: PropTypes.string,
	href: PropTypes.string
};

export default Button;
