import { useEffect, useState } from "react";
import "./CourseDetails.scss";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import { Discord } from "../../assets/svg/discord";
import greaterThan from "../../assets/svg/greater-than.svg";
import previousLesson from "../../assets/svg/previous-lesson.svg";
import nextLesson from "../../assets/svg/next-lesson.svg";
import profile from "../../assets/images/profilepicture.jpeg";
import james from "../../assets/images/james.jpg";
import YoutubeImage from "./YoutubeImage";
import VimeoPlayer from "../../components/VimeoPlayer/VimeoPlayer"
import { LOADING_HIDE, LOADING_SHOW } from "../../ducks/modules/loading";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourses } from "../../ducks/modules/course";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { toLocalTime } from "../../util/date";
import Button from "../../components/Button/Button"

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const CourseDetails = (props) => {
	let { courseId } = useParams();
	const courses = useSelector((state) => state.course.courses);
	const [course, setCourse] = useState(courses);
	const [value, setValue] = useState(0);
	const [selectedTopic, setSelectedTopic] = useState(0);

	useEffect(() => {
		setCourse(courses);
		// setSelectedTopic(_.findLast(courses.topics,(topic)=>{

		// 	return !topic.opening_date || moment(topic.opening_date) < moment()
		// }));
		if (courses.modules) setSelectedTopic(courses.modules[0]);
	}, [courses]);

	const dispatch = useDispatch();

	useEffect(() => {
		async function fetchData() {
			await dispatch(fetchCourses(courseId));
		}
		fetchData();
	}, []);

	const changeTopic = (topic) => {
		dispatch({ type: LOADING_SHOW });
		setSelectedTopic(topic);
		// setTimeOut because of waiting for the iframe re-renders

		setTimeout(() => {
			dispatch({ type: LOADING_HIDE });
		}, 2500);
	};

	const videoLessons = () => selectedTopic.lessons;
	const zoomLessons = () => selectedTopic.live_sessions;

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const topicOpen = (topic) => {
		if (topic.opening_date && moment(topic.opening_date) > moment())
			return false;
		return true;
	};

	return (
		<section className="course-details-contents">
			<div className="course-breadcrumb">
				<Link className="course-breadcrumb-link" to="/courses">
					Course
				</Link>
				<img className="greater-than" src={greaterThan} alt="" />
				<span>{course.name}</span>
			</div>
			<Grid container className="about">
				<Grid item xs={12} md={6}>
					<h3 className="course-description-title">About the course</h3>
					<p>{course.description}</p>
				</Grid>
				<Grid item xs={12} md={6} className="more-detail">
					<span className="total-lessons">
						Total Lessons: {course.totalLessons || "0"}
					</span>
					<span className="total-duration">
						Duration: {course.duration}
					</span>
				</Grid>
			</Grid>

			<div>
				<div>
					<h3 className="instructor-description-title">
						{(course.instructors &&
							course.instructors.length > 1 &&
							"Instructors") ||
							"Instructor"}
					</h3>
				</div>

				<Grid container spacing={4}>
					<Grid item xs={12} md={6} className="instructors-wrapper">
						<div className="instructor-profile">
							<div className="instructor-image-container">
								<img src={profile} alt="" />
							</div>
							<p>Brang</p>
						</div>
						<p className="instructor-about">
							Brang is originally from Kachin State, Myanmar and currently
							living and working in Norway as a web developer for more than
							4years. Passionate about social entrepreneurship and to share
							his knowledge about programming, he runs MSquare Programming
							where he teaches students from Myanmar about web development.
						</p>
					</Grid>
					<Grid item xs={12} md={6} className="instructors-wrapper">
						<div className="instructor-profile">
							<div className="instructor-image-container">
								<img src={james} alt="" />
							</div>
							<p>James Kim</p>
						</div>
						<p className="instructor-about">
							James Kim is a Korean American who had a successful career as a
							software developer in New York for 15 years. Since 2018, James
							has been running an IT solutions agency in Yangon, focused on
							web and mobile app development. James desires to help better
							equip individuals to move forward in their life ambitions and to
							become leaders in their professional community. 
						</p>
					</Grid>
				</Grid>


			</div>

			{/* Lessions Section */}
			{/* Navigator */}

			<div className="course-lessons-navigator">
				<button>
					<img src={previousLesson} alt="" />
				</button>
				<div className="course-lessons-navigator-weeks">
					{course.modules &&
						course.modules.map((topic) => (
							<span
								key={topic.id}
								className={`topic-title
									${selectedTopic && selectedTopic.id === topic.id && "active"}
									${!topicOpen(topic) && "disabled"}
								`}
								onClick={() => {
									if (topicOpen(topic)) changeTopic(topic);
								}}
							>
								{topic.name}
							</span>
						))}
				</div>
				<button>
					<img src={nextLesson} alt="" />
				</button>
			</div>


			<div className="course-lessons">
				<AppBar position="static">
					<Tabs
						value={value}
						indicatorColor="primary"
						onChange={handleChange}
						variant="fullWidth"
					>
						<Tab label="Lessons" {...a11yProps(0)}  />
						<Tab label="Assignments" {...a11yProps(2)} />
						<Tab label="Live Sessions" {...a11yProps(1)} />
					</Tabs>
				</AppBar>

				<div className="tabpanel-container">
					<TabPanel value={value} index={0}  className="panel">
						<div className="course-lessons-videos-container">
							{selectedTopic &&
								videoLessons().map((lesson, index) => (
									<div
										key={index}
										className="course-lessons-videos-container-sub"
									>
										<h3 className="course-lessons-videos-title">
											{(index + 1) + '. ' + lesson.title}
										</h3>
										<div className="course-video-thumbnail-container">
											{lesson.vimeo_id?

												<VimeoPlayer vimeoId={lesson.vimeo_id}></VimeoPlayer>:
												<YoutubeImage videoLink={lesson.video_link} /> 
											}
											{/* <YoutubeImage videoLink={lesson.video_link} /> */}
										</div>
										<p className="course-lessons-videos-content" dangerouslySetInnerHTML={ {__html: lesson.content} }></p>
										{index < videoLessons().length - 1 && 
										<hr/>
										}
									</div>
									
								))}
							{selectedTopic && videoLessons().length == 0 && (
								<div>No Lessons Available for {selectedTopic.name}</div>
							)}
						</div>
					</TabPanel>

					<TabPanel value={value} index={1}  className="panel">
						{selectedTopic &&
							selectedTopic.assignments.map((assignment) => {
								return (
									<div className="assignment">
										<p className="assignment-brand">ASSIGNMENT</p>
										<h3 className="assignment-header">{assignment.name}</h3>
										<p
											className="assignment-para"
											dangerouslySetInnerHTML={{
												__html: assignment.description,
											}}
										></p>
										<p className="assignment-date">
											Due Date :{" "}
											<span className="assignment-span">
												{assignment.due_date}
											</span>{" "}
										</p>
										{assignment.discord_channel && 
										<div className='action'>
											<Button onClick={()=>window.open(assignment.discord_channel, '_blank')}>Submit Assignment</Button>
										</div>
										}
										
									</div>
								);
							})}
					</TabPanel>

					<TabPanel value={value} index={2} className="panel">
						{selectedTopic &&
							zoomLessons().map((zoom, index) => (
								<div key={index} className="zoom-lessons-container">
									<div className="zoom-lesson">
										<Grid container spacing={3}>
											<Grid item xs={12} sm={3}>
												Title:{" "}
											</Grid>
											<Grid item xs={12} sm={9}>
												{zoom.title}
											</Grid>

											<Grid item xs={12} sm={3}>Description: </Grid>
											<Grid item xs={12} sm={9}><div dangerouslySetInnerHTML={ {__html: zoom.description} }></div></Grid>


											<Grid item xs={12} sm={3}>
												Starting Time:{" "}
											</Grid>
											<Grid item xs={12} sm={9}>
												{zoom.starting_date_time
													? toLocalTime(zoom.starting_date_time)
													: "TBA"}
											</Grid>

											<Grid item xs={12} sm={3}>
												Ending Time:{" "}
											</Grid>
											<Grid item xs={12} sm={9}>
												{zoom.ending_date_time
													? toLocalTime(zoom.ending_date_time)
													: "TBA"}
											</Grid>

											<Grid item xs={12} className='action'>
												<Button onClick={()=>window.open(zoom.zoom_link, '_blank')}>Join</Button>
											</Grid>
										</Grid>
									</div>
								</div>
							))}
					</TabPanel>
				</div>
				
				
			</div>
			<div className="bubble-container">
				<div className="discuss-bubble" onClick={() => window.open(selectedTopic.discord_link, "_blank")}>
					<Discord className="svg-icon" />
					<div>Discuss</div>
				</div>
			</div>
		</section>
	);
};

export default CourseDetails;
