/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import './Header.scss';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Menu, MenuItem } from '@material-ui/core';
import {
	changeIntoEnglish,
	changeIntoMyanmar,
} from '../../ducks/modules/language';

import { Hamburger } from '../../assets/svg/Hamburger';
import hgLogo from '../../assets/images/hg_logo.png';
import facebookIcon from '../../assets/svg/facebook-icon.svg';
import facebookIconTwo from '../../assets/svg/facebook-two.svg';
import instagramIcon from '../../assets/svg/instagram-icon.svg';
import instagramIconTwo from '../../assets/svg/instagram-two.svg';
import arrowDown from '../../assets/svg/arrow-down.svg';
import profilePicture from '../../assets/images/profilepicture.jpeg';
import { logOut } from '../../ducks/modules/user';

const Header = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const isLoggedIn = useSelector(
		(state) => state.user && state.user.user !== null
	);
	const user = useSelector((state) => {
		return state.user && state.user.user;
	});
	const isEnglish = useSelector((state) => state.language.isEnglish);
	const isMyanmar = useSelector((state) => state.language.isMyanmar);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const logOutClicked = () => {
		dispatch(logOut());
	};

	return (
		<header className="header">
			<div className="container-content">
				<nav className="left-content">
					<ul>
						<li>
							<Link
								className={`navigation-links ${
									location.pathname === '/' &&
									'navigation-links-active'
								}`}
								to="/"
							>
								Home
							</Link>
						</li>
						<li>
							<Link
								className={`navigation-links ${
									location.pathname.includes('/courses') &&
									'navigation-links-active'
								}`}
								to="/courses"
							>
								Courses
							</Link>
						</li>
						<li>
							<Link
								className={`navigation-links ${
									location.pathname.includes(
										'/students/works'
									) && 'navigation-links-active'
								}`}
								to="/students/works"
							>
								Student Works
							</Link>
						</li>
					</ul>
				</nav>

				<div className="logo-container">
					<Link to="/">
						<img
							className="logo"
							src={hgLogo}
							alt="IT Training & Recuritment"
						/>
					</Link>
				</div>

				<div className="right-content">
					{isLoggedIn && user ? (
						<>
							<Button
								aria-controls="user-menu"
								aria-haspopup="true"
								onClick={handleClick}
							>
								<div className="user-info">
									<span className="profile-photo">
										<img
											src={
												user.avatar_url !== null
													? user.avatar_url
													: profilePicture
											}
										></img>
									</span>
									<span className="profile-name">
										{user.name}
									</span>
									<img
										src={arrowDown}
										className="arrow-down"
									/>
								</div>
							</Button>
							<Menu
								elevation={0}
								id="user-menu"
								anchorEl={anchorEl}
								getContentAnchorEl={null}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
							>
								<Link
									className="profile-link"
									to={`/profile/${user.id}`}
								>
									<MenuItem onClick={handleClose}>
										Profile
									</MenuItem>
								</Link>

								<MenuItem onClick={logOutClicked}>
									Logout
								</MenuItem>
							</Menu>
						</>
					) : (
						<Link
							to={{
								pathname: '/signin',
								state: location.pathname,
							}}
							className="sign-in-link"
						>
							SIGN IN
						</Link>
					)}

					<Link to="" className="social-icons">
						<img src={facebookIcon} alt="" />
					</Link>
					<Link to="" className="social-icons">
						<img src={instagramIcon} alt="" />
					</Link>
					<div className={`language-buttons`}>
						<button
							onClick={() => dispatch(changeIntoMyanmar())}
							className={`myanmar ${
								isMyanmar ? 'selected-language' : null
							}`}
						>
							MM
						</button>
						<button
							onClick={() => dispatch(changeIntoEnglish())}
							className={`english ${
								isEnglish ? 'selected-language' : null
							}`}
						>
							ENG
						</button>
					</div>

					<div
						onClick={() => setSidebarOpen(true)}
						className="hamburger-container"
					>
						<p>
							<Hamburger className="sidebar-header-hamburger" />
						</p>
					</div>
				</div>
			</div>

			{/* Sidebar */}

			<div
				className={`sidebarContainer ${
					sidebarOpen
						? 'sidebarPositionShown'
						: 'sidebarPositionHidden'
				} `}
			>
				<div
					className="sidebar-close"
					onClick={() => setSidebarOpen(false)}
				>
					<p className="sidebar-close-button">X</p>
				</div>

				<div className="sidebar-language-buttons">
					<button
						onClick={() => dispatch(changeIntoMyanmar())}
						className={`${
							isMyanmar
								? 'sidebar-selected-language'
								: 'sidebar-unselected-language'
						}`}
					>
						MM
					</button>
					<button
						onClick={() => dispatch(changeIntoEnglish())}
						className={`${
							isEnglish
								? 'sidebar-selected-language'
								: 'sidebar-unselected-language'
						}`}
					>
						ENG
					</button>
				</div>

				<div className="sidebar-elements">
					<div className="sidebar-text-container">
						<Link
							className={`sidebar-elements-inactive ${
								location.pathname === '/' &&
								'sidebar-elements-active'
							}`}
							to="/"
							onClick={() => setSidebarOpen(false)}
						>
							Home
						</Link>
					</div>

					<div className="sidebar-text-container">
						<Link
							className={`sidebar-elements-inactive ${
								location.pathname.includes('/courses') &&
								'sidebar-elements-active'
							}`}
							onClick={() => setSidebarOpen(false)}
							to="/courses"
						>
							Courses
						</Link>
					</div>
					<div className="sidebar-text-container">
						<Link
							className={`sidebar-elements-inactive ${
								location.pathname.includes('/students/works') &&
								'sidebar-elements-active'
							}`}
							onClick={() => setSidebarOpen(false)}
							to="/students/works"
						>
							Student Works
						</Link>
					</div>
				</div>

				<div className="sidebar-icon-container">
					<Link to="" className="sidebar-icon">
						<img src={facebookIconTwo} alt="Facebook" />
					</Link>
					<Link to="" className="sidebar-icon">
						<img src={instagramIconTwo} alt="Instagram" />
					</Link>
				</div>
			</div>
		</header>
	);
};

export default Header;
